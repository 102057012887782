<template>
  <div class="home">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      :loading-text="loadingText"
      :pulling-text="pullingText"
      :loosing-text="loosingText"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="bomTitle"
        :offset="20"
        class="main"
        loading-text="Loading..."
      >
        <div class="newsList" v-for="(item, index) in list" :key="index">
          <div
            class="newsList_center"
            v-if="!item.type"
            @click="goLanding(item.id)"
          >
            <div class="word">
              <div class="title">{{ item.title }}</div>
              <div class="newsInfo">
                <div>
                  {{ item.channel }}
                </div>
                <div class="round"></div>
                <div>
                  {{
                    $global.formatDate(item.publishedTime, "yyyy-MM-dd hh:mm")
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="adsItem" v-if="item.type && item.type === -1">
            <ins
              class="adsbygoogle"
              style="display: block"
              :data-ad-client="data_ad_client"
              :data-ad-slot="slot1"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </div>

          <div class="adsItem" v-if="item.type && item.type === -2">
            <ins
              class="adsbygoogle"
              style="display: block"
              :data-ad-client="data_ad_client"
              :data-ad-slot="slot2"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <div class="loading" v-if="!requirDataAlready">
      <img src="@/assets/news/loading.png" alt="" />
    </div>
  </div>
</template>
<script>
import '@/css/news/home.scss';
export default {
  name: "home",
  data() {
    return {
      list: [],
      hasNext: false,
      isLoading: false, // 控制下拉刷新的加载状态
      loading: false, // 控制上拉加载的加载状态
      finished: false, // 是否已加载完全部数据
      bomTitle: '-- END --', // 上拉加载完成时的文本
      loadingText: 'Under loading...', // 加载过程提示文案	
      pullingText: 'Pull down to refresh...', //下拉过程提示文案	
      loosingText: 'Release to refresh...',   // 释放过程提示文案	
      page: 1,
      type: '',
      isinitialization: false,
      isData: false,
      requirDataAlready: true,
      isClose: false,
      time: "",
      slot1: '',
      slot2: "",
      data_ad_client: "",
    }
  },
  created() {
    this.getInfo().then(() => {
      this.getList()
    })
  },
  mounted() {
    this.type = this.$route.query.type
    this.$eventrackFb(`show_page_h5_${this.$store.state.rootDomain}`, 'expose', {
      pve_cur: "/h5page/x/x/",
      website: this.website,
      type: this.type
    });
    this.$nextTick(() => {
      console.log(Date.now(), 'nextTick')
      this.isClose = true
    })
  },
  methods: {
    getInfo() {
      const href = window.location.href
      let domain = new URL(href).hostname
      if (domain === 'localhost') {
        domain = "www.shop13.fyi"
      }
      if (domain.substr(0, 3) !== 'www') {
        domain = 'www.' + domain
        this.$store.commit("UPDATE_ROOTDOMAIN", domain);
      } else {
        let rootDomain = domain.substr(4, domain.length)
        this.$store.commit("UPDATE_ROOTDOMAIN", rootDomain);
      }
      return this.$api.getNewsInfo({ domain }).then(res => {
        if (res.result_code == '200') {

          const { slot1, slot2, slot3, data_ad_client } = res.data
          this.slot1 = slot1
          this.slot2 = slot2
          this.data_ad_client = data_ad_client
          this.$store.commit("UPDATE_SLOT3", slot3);
          this.$store.commit("UPDATE_DATA_AD_CLIENT", data_ad_client);
        }
      })

    },
    getList() {
      return this.$api.getList({
        page: this.page,
        sdu: 2,
        size: 30,
      }).then((res
      ) => {
        console.log(111)
        this.loading = false;
        this.isLoading = false;
        this.finished = !res.data.hasNext;
        if (res.result_code === 200) {
          let data = res.data.source
          this.list.push(...data)
          if (this.list.length > 3 && !this.list[3].type) {
            this.list.splice(3, 0, {
              type: -1
            })
          }
          if (this.list.length > 7 && !this.list[7].type) {
            this.list.splice(7, 0, {
              type: -2
            })
          }
          if (this.page === 1) {
            setTimeout(() => {
              window.addAds();
            }, 100)
          }
          this.page = this.page + 1
          if (this.list.length > 0) {
            this.isData = true
          }
          this.hasNext = res.data.hasNext
        } else {
          this.requirDataAlready = true
        }
        if (!this.isinitialization) {
          this.isinitialization = true
          this.$eventrackFb(`show_pageNews_h5_${this.$store.state.rootDomain}`, 'expose', {
            website: this.website,
            pve_cur: "/h5page/news/x/",
            type: this.type,
            state: res.result_code === 200 && res.data.source.length > 1 ? 1 : 0
          });
        }
      }).catch((err) => {
        this.requirDataAlready = true
        this.isinitialization = true
        this.$eventrackFb(`show_pageNews_h5_${this.$store.state.rootDomain}`, 'expose', {
          website: this.website,
          pve_cur: "/h5page/news/x/",
          type: this.type,
          state: -1
        });
      });
    },
    goLanding(id) {
      this.$eventrackFb(`clicknews_h5_${this.$store.state.rootDomain}`, 'click', {
        website: this.website,
        id: id,
      });
      this.$router.push({ name: 'landing', query: { id: id } })

    },
    onRefresh() {
      console.log('onRefresh')
      this.finished = false;
      this.loading = true;
      this.page = 1
      setTimeout(() => {
        this.isLoading = false; // 结束刷新状态
      }, 2000); // 2秒后结束刷新状态
      this.onLoad();
    },
    onLoad() {
      console.log('onLoad')
      this.getList()
    },
    isClickClose() {
      this.isClose = false
    }
  }
};
</script>